var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.config.readonly() || _vm.column.readonly)?_c('div',{staticClass:"vs-con-input"},[_c('div',{staticClass:"vs-inputx vs-input--input normal hasValue whitespace-pre-wrap",staticStyle:{"background":"#f8f8f8"},domProps:{"innerHTML":_vm._s(_vm.readonlyValue(_vm.entity, _vm.column))}})]):(_vm.column.type === 'datetime')?[_c('datetime',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.column.validation || ''),expression:"column.validation || ''"}],attrs:{"value":_vm.entity[_vm.column.key] ? _vm.entity[_vm.column.key].toDate().toISOString() : null,"type":"datetime","input-class":"flatpickr-input vs-input--input","format":"dd-LL-yyyy HH:mm","phrases":{
        ok: _vm.$t('buttons.ok'),
        cancel: _vm.$t('buttons.cancel'),
      },"minute-step":5,"name":_vm.column.key,"data-vv-as":_vm.$t(((_vm.config.prefix) + ".fields." + (_vm.column.key)))},on:{"input":function (event) { return _vm.config.onChange(_vm.entity.id, _vm.column.key, event); }}}),(_vm.column.validation !== 'required' && !!_vm.entity[_vm.column.key])?_c('a',{staticClass:"flatpickr-clear",on:{"click":function($event){return _vm.config.onChange(_vm.entity.id, _vm.column.key, null)}}},[_c('feather-icon',{staticClass:"flatpickr-clear-button ml-1",attrs:{"icon":"XIcon","svgClasses":"h-4 w-4"}})],1):_vm._e()]:(_vm.column.type === 'date')?[_c('datetime',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.column.validation || ''),expression:"column.validation || ''"}],attrs:{"value":_vm.entity[_vm.column.key] ? _vm.entity[_vm.column.key].toDate().toISOString() : null,"type":"date","input-class":"flatpickr-input vs-input--input","format":"dd-LL-yyyy","phrases":{
        ok: _vm.$t('buttons.ok'),
        cancel: _vm.$t('buttons.cancel'),
      },"minute-step":5,"name":_vm.column.key,"data-vv-as":_vm.$t(((_vm.config.prefix) + ".fields." + (_vm.column.key))),"value-zone":"UTC","zone":"UTC"},on:{"input":function (event) { return _vm.config.onChange(_vm.entity.id, _vm.column.key, event); }}}),(_vm.column.validation !== 'required' && !!_vm.entity[_vm.column.key])?_c('a',{staticClass:"flatpickr-clear",on:{"click":function($event){return _vm.config.onChange(_vm.entity.id, _vm.column.key, null)}}},[_c('feather-icon',{staticClass:"flatpickr-clear-button ml-1",attrs:{"icon":"XIcon","svgClasses":"h-4 w-4"}})],1):_vm._e()]:(_vm.column.type === 'input')?_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.column.validation),expression:"column.validation"}],staticClass:"w-full",attrs:{"value":_vm.entity[_vm.column.key],"name":_vm.column.key,"data-vv-as":_vm.$t(((_vm.config.prefix) + ".fields." + (_vm.column.key)))},on:{"change":function (event) { return _vm.config.onChange(_vm.entity.id, _vm.column.key, event.target.value); }}}):(_vm.column.type === 'select')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.column.validation),expression:"column.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"alias","reduce":function (option) { return _vm.column.valueFormatter
          ? _vm.column.valueFormatter(option)
          : { id: option.id, alias: option.alias }; },"options":_vm.column.extra.optionsFunction
        ? _vm.column.extra.optionsFunction(_vm.model, _vm.entity)
        : _vm.column.extra.options,"getOptionLabel":function (o) { return _vm.getOptionLabel(o, _vm.column, _vm.entity); },"clearable":!_vm.column.validation || _vm.column.validation.indexOf('required') < 0,"filterable":!_vm.column.extra.search,"value":_vm.entity[_vm.column.key],"filterBy":_vm.selectFilter,"data-vv-as":_vm.$t(((_vm.config.prefix) + ".fields." + (_vm.column.key)))},on:{"search":function (val) {
        _vm.column.extra.search ? _vm.column.extra.search(val, _vm.model) : function () {};
      },"input":function (val) { return _vm.config.onChange(_vm.entity.id, _vm.column.key, val); }}}):(_vm.column.type === 'enum')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.column.validation),expression:"column.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"alias","reduce":function (option) { return _vm.column.valueFormatter
          ? _vm.column.valueFormatter(option)
          : { id: option.id, key: option.key, alias: option.alias }; },"options":(_vm.column.extra.optionsFunction
        ? _vm.column.extra.optionsFunction(_vm.model, _vm.entity)
        : _vm.column.extra.options
      )
        .map(function (x) { return ({
          id: x.id,
          key: x.key,
          alias: _vm.$t(("enums." + (_vm.column.extra.enumType) + "." + (x.key))),
        }); })
        .sort(function (a, b) {
          return _vm.column.extra.customSort
            ? _vm.column.extra.customSort(a, b)
            : a.alias.localeCompare(b.alias);
        }),"getOptionLabel":function (o) { return _vm.getOptionLabel(o, _vm.column, _vm.entity); },"clearable":!_vm.column.validation || _vm.column.validation.indexOf('required') < 0,"filterable":!_vm.column.extra.search,"value":_vm.entity[_vm.column.key],"filterBy":_vm.selectFilter,"data-vv-as":_vm.$t(((_vm.config.prefix) + ".fields." + (_vm.column.key)))},on:{"search":function (val) {
        _vm.column.extra.search ? _vm.column.extra.search(val, _vm.model) : function () {};
      },"input":function (val) { return _vm.config.onChange(_vm.entity.id, _vm.column.key, val); }}}):(_vm.column.type === 'number')?_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.column.validation),expression:"column.validation"}],staticClass:"w-full",attrs:{"type":"number","value":_vm.entity[_vm.column.key],"name":_vm.column.key,"lang":_vm.$i18n.locale,"data-vv-as":_vm.$t(((_vm.config.prefix) + ".fields." + (_vm.column.key)))},on:{"change":function (event) { return _vm.config.onChange(_vm.entity.id, _vm.column.key, event.target.value); }}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }