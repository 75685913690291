<template>
  <div>
    <div class="vs-con-input" v-if="config.readonly() || column.readonly">
      <div
        class="vs-inputx vs-input--input normal hasValue whitespace-pre-wrap"
        style="background: #f8f8f8"
        v-html="readonlyValue(entity, column)"
      />
    </div>
    <template v-else-if="column.type === 'datetime'">
      <datetime
        :value="
          entity[column.key] ? entity[column.key].toDate().toISOString() : null
        "
        type="datetime"
        input-class="flatpickr-input vs-input--input"
        format="dd-LL-yyyy HH:mm"
        :phrases="{
          ok: $t('buttons.ok'),
          cancel: $t('buttons.cancel'),
        }"
        :minute-step="5"
        :name="column.key"
        v-validate="column.validation || ''"
        :data-vv-as="$t(`${config.prefix}.fields.${column.key}`)"
        @input="(event) => config.onChange(entity.id, column.key, event)"
      />
      <a
        class="flatpickr-clear"
        @click="config.onChange(entity.id, column.key, null)"
        v-if="column.validation !== 'required' && !!entity[column.key]"
      >
        <feather-icon
          icon="XIcon"
          svgClasses="h-4 w-4"
          class="flatpickr-clear-button ml-1"
        />
      </a>
    </template>
    <template v-else-if="column.type === 'date'">
      <datetime
        :value="
          entity[column.key] ? entity[column.key].toDate().toISOString() : null
        "
        type="date"
        input-class="flatpickr-input vs-input--input"
        format="dd-LL-yyyy"
        :phrases="{
          ok: $t('buttons.ok'),
          cancel: $t('buttons.cancel'),
        }"
        :minute-step="5"
        :name="column.key"
        v-validate="column.validation || ''"
        :data-vv-as="$t(`${config.prefix}.fields.${column.key}`)"
        @input="(event) => config.onChange(entity.id, column.key, event)"
        value-zone="UTC"
        zone="UTC"
      />
      <a
        class="flatpickr-clear"
        @click="config.onChange(entity.id, column.key, null)"
        v-if="column.validation !== 'required' && !!entity[column.key]"
      >
        <feather-icon
          icon="XIcon"
          svgClasses="h-4 w-4"
          class="flatpickr-clear-button ml-1"
        />
      </a>
    </template>
    <vs-input
      v-else-if="column.type === 'input'"
      class="w-full"
      :value="entity[column.key]"
      :name="column.key"
      v-validate="column.validation"
      :data-vv-as="$t(`${config.prefix}.fields.${column.key}`)"
      @change="
        (event) => config.onChange(entity.id, column.key, event.target.value)
      "
    />
    <v-select
      v-else-if="column.type === 'select'"
      label="alias"
      :reduce="
        (option) =>
          column.valueFormatter
            ? column.valueFormatter(option)
            : { id: option.id, alias: option.alias }
      "
      :options="
        column.extra.optionsFunction
          ? column.extra.optionsFunction(model, entity)
          : column.extra.options
      "
      :getOptionLabel="(o) => getOptionLabel(o, column, entity)"
      :clearable="
        !column.validation || column.validation.indexOf('required') < 0
      "
      @search="
        (val) => {
          column.extra.search ? column.extra.search(val, model) : () => {};
        }
      "
      :filterable="!column.extra.search"
      :value="entity[column.key]"
      class="mb-4 md:mb-0"
      v-validate="column.validation"
      :filterBy="selectFilter"
      :data-vv-as="$t(`${config.prefix}.fields.${column.key}`)"
      @input="(val) => config.onChange(entity.id, column.key, val)"
    />
    <v-select
      v-else-if="column.type === 'enum'"
      label="alias"
      :reduce="
        (option) =>
          column.valueFormatter
            ? column.valueFormatter(option)
            : { id: option.id, key: option.key, alias: option.alias }
      "
      :options="
        (column.extra.optionsFunction
          ? column.extra.optionsFunction(model, entity)
          : column.extra.options
        )
          .map((x) => ({
            id: x.id,
            key: x.key,
            alias: $t(`enums.${column.extra.enumType}.${x.key}`),
          }))
          .sort((a, b) => {
            return column.extra.customSort
              ? column.extra.customSort(a, b)
              : a.alias.localeCompare(b.alias);
          })
      "
      :getOptionLabel="(o) => getOptionLabel(o, column, entity)"
      :clearable="
        !column.validation || column.validation.indexOf('required') < 0
      "
      @search="
        (val) => {
          column.extra.search ? column.extra.search(val, model) : () => {};
        }
      "
      :filterable="!column.extra.search"
      :value="entity[column.key]"
      class="mb-4 md:mb-0"
      v-validate="column.validation"
      :filterBy="selectFilter"
      :data-vv-as="$t(`${config.prefix}.fields.${column.key}`)"
      @input="(val) => config.onChange(entity.id, column.key, val)"
    />
    <vs-input
      v-else-if="column.type === 'number'"
      type="number"
      class="w-full"
      :value="entity[column.key]"
      :name="column.key"
      :lang="$i18n.locale"
      v-validate="column.validation"
      :data-vv-as="$t(`${config.prefix}.fields.${column.key}`)"
      @change="
        (event) => config.onChange(entity.id, column.key, event.target.value)
      "
    />
  </div>
</template>
<script>
import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";
import vSelect from "vue-select";

export default {
  name: "MultiEditField",
  components: {
    datetime: Datetime,
    vSelect,
  },
  props: {
    config: Object,
    column: Object,
    entity: Object,
    model: Object,
  },
  methods: {
    selectFilter(option, label, search) {
      const removeDiacritics = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      return (
        removeDiacritics(label).toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },

    getOptionLabel(option, c, entity) {
      if (option) {
        let options = c.extra.optionsFunction
          ? c.extra.optionsFunction(this.model, entity)
          : c.extra.options;
        if (["enum", "multiple-enum"].includes(c.type)) {
          options = options.map((x) => ({
            id: x.id,
            key: x.key,
            alias: this.$t(`enums.${c.extra.enumType}.${x.key}`),
          }));
        }

        let optionId = option.id ? option.id : option;
        let found = options.find((v) => v.id === optionId) || {};
        return found.alias || null;
      } else {
        return null;
      }
    },

    readonlyValue(entity, column) {
      const val = entity[column.key];
      if (val !== null && val !== undefined && val !== "") {
        if (column.type === "select") {
          return val.alias;
        } else if (column.type === "boolean") {
          return this.$t(`boolean.${val ? "yes" : "no"}`);
        } else if (column.type === "datetime") {
          return this.$moment(val.toDate()).format("DD-MM-YYYY HH:mm");
        } else if (column.type === "date") {
          return this.$moment(val.toDate()).format("DD-MM-YYYY");
        } else if (column.type === "multiple-select") {
          return (val || []).map((x) => x.alias).join(", ");
        } else if (!["input", "text", "number"].includes(column.type)) {
          console.log("HACER ESTE: ", column.type);
        }

        return val;
      } else {
        return "&nbsp;";
      }
    },
  },
};
</script>
