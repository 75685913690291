var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.originalData)?_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"vx-col w-full"},[_c('vx-card',{class:_vm.processedTabs.length === 1 ? 'hide-tabs' : ''},[_vm._t("formHeader",null,null,_vm.headerData),_c('vs-tabs',{attrs:{"alignment":"fixed"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.processedTabs),function(t,i){return _c('vs-tab',{key:("tab_" + i),attrs:{"label":_vm.$t(t.name)}},[_vm._l((t.rows),function(r,j){return [(
                j > 0 &&
                r.attributes.some(
                  function (f) { return !(
                      (!!f.visibility && !f.visibility(_vm.model)) ||
                      f.hideOnInvisible
                    ); }
                )
              )?_c('vs-divider',{key:("separator_" + i + "_" + j),attrs:{"border-style":"dashed","color":"primary"}}):_vm._e(),_c('div',{key:("row_" + i + "_" + j),staticClass:"vx-row"},[_vm._l((r.attributes),function(f,p){
              var _obj;
return [_c('div',{key:("field_" + p),staticClass:"vx-col w-full mb-2",class:( _obj = {}, _obj[("sm:w-" + (f.size))] = true, _obj.hidden = !(!f.visibility || f.visibility(_vm.model)) &&
                      f.hideOnInvisible, _obj )},[(!f.visibility || f.visibility(_vm.model))?[(!!f.attribute && !f.hideLabel)?_c('label',{staticClass:"vs-input--label",class:{
                        'text-danger': _vm.modifications.hasOwnProperty(
                          f.attribute
                        ),
                      }},[_vm._v(" "+_vm._s(_vm.$t((_vm.collectionName + ".fields." + (f.attribute))))+" ")]):_vm._e(),_c('br'),(f.readonly && !['files'].includes(f.type))?_c('div',{staticClass:"vs-con-input"},[_c('div',{staticClass:"vs-inputx vs-input--input normal hasValue whitespace-pre-wrap",staticStyle:{"background":"#f8f8f8"},domProps:{"innerHTML":_vm._s(_vm.readonlyValue(f))}})]):(f.type === 'input')?_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"w-full",attrs:{"name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'number')?_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"w-full",attrs:{"type":"number","name":f.attribute,"lang":_vm.$i18n.locale,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, _vm._n($$v))},expression:"model[f.attribute]"}}):(f.type === 'text')?_c('vs-textarea',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"w-full",attrs:{"name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled,"rows":f.rows || 10},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'color')?_c('sketch-picker',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],attrs:{"value":_vm.model[f.attribute] || 'rgb(115, 103, 240)',"name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},on:{"input":function (e) { return _vm.updateColor(e, f); }}}):(f.type === 'datetime')?[_c('datetime',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation || ''),expression:"f.validation || ''"}],attrs:{"type":"datetime","input-class":"flatpickr-input vs-input--input","format":"dd-LL-yyyy HH:mm","phrases":{
                          ok: _vm.$t('buttons.ok'),
                          cancel: _vm.$t('buttons.cancel'),
                        },"minute-step":5,"name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                            ? f.disabledFunction(_vm.model)
                            : f.disabled},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}),(
                          f.validation !== 'required' && !!_vm.model[f.attribute]
                        )?_c('a',{staticClass:"flatpickr-clear",on:{"click":function($event){_vm.model[f.attribute] = null}}},[_c('feather-icon',{staticClass:"flatpickr-clear-button ml-1",attrs:{"icon":"XIcon","svgClasses":"h-4 w-4"}})],1):_vm._e()]:(f.type === 'date')?[_c('datetime',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation || ''),expression:"f.validation || ''"}],attrs:{"type":"date","input-class":"flatpickr-input vs-input--input","format":"dd-LL-yyyy","phrases":{
                          ok: _vm.$t('buttons.ok'),
                          cancel: _vm.$t('buttons.cancel'),
                        },"minute-step":5,"name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                            ? f.disabledFunction(_vm.model)
                            : f.disabled,"value-zone":"UTC","zone":"UTC"},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}),(
                          f.validation !== 'required' && !!_vm.model[f.attribute]
                        )?_c('a',{staticClass:"flatpickr-clear",on:{"click":function($event){_vm.model[f.attribute] = null}}},[_c('feather-icon',{staticClass:"flatpickr-clear-button ml-1",attrs:{"icon":"XIcon","svgClasses":"h-4 w-4"}})],1):_vm._e()]:(f.type === 'enum')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"alias","reduce":function (option) { return f.valueFormatter
                            ? f.valueFormatter(option)
                            : {
                                id: option.id,
                                key: option.key,
                                alias: option.alias,
                              }; },"options":(f.extra.optionsFunction
                          ? f.extra.optionsFunction(_vm.model)
                          : f.extra.options
                        )
                          .map(function (x) { return ({
                            id: x.id,
                            key: x.key,
                            alias: _vm.$t(("enums." + (f.extra.enumType) + "." + (x.key))),
                          }); })
                          .sort(function (a, b) {
                            return f.extra.customSort
                              ? f.extra.customSort(a, b)
                              : a.alias.localeCompare(b.alias);
                          }),"getOptionLabel":function (o) { return _vm.getOptionLabel(o, f); },"clearable":!f.validation || f.validation.indexOf('required') < 0,"filterable":!f.extra.search,"name":f.attribute,"filterBy":_vm.selectFilter,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},on:{"search":function (val) {
                          f.extra.search
                            ? f.extra.search(val, _vm.model)
                            : function () {};
                        }},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'multiple-enum')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"alias","reduce":function (option) { return f.valueFormatter
                            ? f.valueFormatter(option)
                            : {
                                id: option.id,
                                key: option.key,
                                alias: option.alias,
                              }; },"options":(f.extra.optionsFunction
                          ? f.extra.optionsFunction(_vm.model)
                          : f.extra.options
                        )
                          .map(function (x) { return ({
                            id: x.id,
                            key: x.key,
                            alias: _vm.$t(("enums." + (f.extra.enumType) + "." + (x.key))),
                          }); })
                          .sort(function (a, b) {
                            return f.extra.customSort
                              ? f.extra.customSort(a, b)
                              : a.alias.localeCompare(b.alias);
                          }),"getOptionLabel":function (o) { return _vm.getOptionLabel(o, f); },"clearable":!f.validation || f.validation.indexOf('required') < 0,"multiple":"","name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'select')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"alias","reduce":function (option) { return f.valueFormatter
                            ? f.valueFormatter(option)
                            : { id: option.id, alias: option.alias }; },"options":f.extra.optionsFunction
                          ? f.extra.optionsFunction(_vm.model)
                          : f.extra.options,"getOptionLabel":function (o) { return _vm.getOptionLabel(o, f); },"clearable":!f.validation || f.validation.indexOf('required') < 0,"filterable":!f.extra.search,"name":f.attribute,"filterBy":_vm.selectFilter,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},on:{"search":function (val) {
                          f.extra.search
                            ? f.extra.search(val, _vm.model)
                            : function () {};
                        }},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'multiple-select')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"alias","reduce":function (option) { return f.valueFormatter
                            ? f.valueFormatter(option)
                            : { id: option.id, alias: option.alias }; },"options":f.extra.optionsFunction
                          ? f.extra.optionsFunction(_vm.model)
                          : f.extra.options,"getOptionLabel":function (o) { return _vm.getOptionLabel(o, f); },"clearable":!f.validation || f.validation.indexOf('required') < 0,"multiple":"","name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'boolean')?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(f.validation),expression:"f.validation"}],staticClass:"mb-4 md:mb-0",attrs:{"reduce":function (option) { return option.value; },"options":[
                        { label: _vm.$t('boolean.yes'), value: true },
                        { label: _vm.$t('boolean.no'), value: false } ],"clearable":!f.validation || f.validation.indexOf('required') < 0,"name":f.attribute,"data-vv-as":_vm.$t((_vm.collectionName + ".fields." + (f.attribute))),"disabled":f.disabledFunction
                          ? f.disabledFunction(_vm.model)
                          : f.disabled},model:{value:(_vm.model[f.attribute]),callback:function ($$v) {_vm.$set(_vm.model, f.attribute, $$v)},expression:"model[f.attribute]"}}):(f.type === 'list')?_c('ListPage',{attrs:{"gridData":f.extra.data(),"gridConfig":f.extra.gridConfig,"filters":f.extra.filters},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)}):(f.type === 'multiEdit')?_c('MultiEdit',{attrs:{"config":f.config,"model":_vm.model}}):(f.type === 'custom')?_vm._t(f.template,null,null,Object.assign({}, {model: _vm.model}, f.data)):(f.type === 'alert')?_c('vs-alert',{attrs:{"active":true,"color":f.color,"icon":f.icon}},[_c('span',[_vm._v(_vm._s(f.text))])]):(f.type === 'files')?_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-base"},[(
                            !f.readonly &&
                            !(f.disabledFunction
                              ? f.disabledFunction(_vm.model)
                              : f.disabled)
                          )?_c('vue-dropzone',{ref:f.attribute,refInFor:true,attrs:{"id":"drop2","options":f.extra.config},on:{"vdropzone-file-added":function (file) { return _vm.onFileAdded(file, f.attribute, f); }}}):_vm._e()],1),(f.extra.listingTemplate)?_vm._t(f.extra.listingTemplate,null,null,{ files: f.extra.files() }):_vm._l((f.extra.files()),function(file,id){return _c('div',{key:id,staticClass:"vx-col w-full lg:w-1/5 sm:w-1/2 mb-base"},[_c('vx-card',{staticClass:"overlay-card overflow-hidden"},[_c('template',{slot:"no-body"},[_c('img',{staticClass:"responsive blur-1",attrs:{"src":_vm.filePreviewCover(file.url, file.type)}}),_c('div',{staticClass:"card-overlay text-white"},[_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"mt-0 w-full flex justify-between"},[_c('p',{staticClass:"text-white mb-2 tracking-wide font-medium",staticStyle:{"max-width":"85%","overflow":"hidden"}},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('a',{staticClass:"leading-none",staticStyle:{"cursor":"pointer"},attrs:{"target":"_blank","href":file.url}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","svgClasses":"w-6 h-6 text-white"}})],1)]),_c('div',{staticClass:"text-center w-full absolute bottom-0"},[_c('div',{staticClass:"flex justify-between text-sm"},[(!f.isReadonly)?_c('a',{staticClass:"leading-none",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.confirmRemoveFile(
                                          file.id,
                                          file.name,
                                          f.attribute
                                        )}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","svgClasses":"w-6 h-6 text-white"}})],1):_vm._e(),_c('p',[_vm._v(" "+_vm._s(file.name.substring( file.name.lastIndexOf(".") + 1 ))+" - "+_vm._s(( file.size / Math.pow(1024, 2) ).toFixed(2))+"MB ")]),_c('a',{staticClass:"leading-none",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadWithVueResource(
                                          file.url,
                                          file.name
                                        )}}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon","svgClasses":"w-6 h-6 text-white"}})],1)])])])])])],2)],1)})],2):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(f.attribute)),expression:"errors.has(f.attribute)"}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first(f.attribute))+" ")])]:_vm._e()],2)]})],2)]})],2)}),1)],2)],1),(!_vm.formIsReadOnly)?_c('div',{staticClass:"vx-col w-full mt-base"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"color":"success","disabled":!_vm.formForSave},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))]),_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"color":"danger","type":"border"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")]),_vm._t("customButtons",null,null,_vm.customButtonsData),(_vm.showDeleteButton)?_c('vs-button',{staticClass:"mr-3 mb-2 float-right",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-trash"},on:{"click":_vm.onRemove}},[_vm._v(" "+_vm._s(_vm.$t("buttons.remove"))+" ")]):_vm._e()],2)])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }