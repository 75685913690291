<template>
  <div>
    <div class="vx-row m-1">
      <div class="vx-col sm:w-1/12 text-center" v-if="!config.readonly()">
        <feather-icon
          icon="PlusIcon"
          svgClasses="w-6 h-6 text-primary m-2 cursor-pointer"
          @click="() => config.onAdd(model)"
        />
      </div>
      <div
        class="vx-col sm:w-11/12 text-center"
        v-if="config.entities().length == 0"
      >
        {{ $t(`${config.prefix}.labels.noElements`) }}
      </div>
      <div class="vx-col sm:w-11/12 vx-row" v-else>
        <div
          class="vx-col"
          :class="`md:w-${c.size} sm:w-${c.mobileSize} ${c.extraClasses || ''}`"
          v-for="(c, pos) in config.columns"
          :key="`header_${pos}`"
        >
          <b v-if="c.type === 'many'">
            {{
              c.fields
                .map((x) => $t(`${config.prefix}.fields.${x.key}`))
                .join(" / ")
            }}
          </b>
          <b v-else>{{ $t(`${config.prefix}.fields.${c.key}`) }}</b>
        </div>
      </div>
    </div>
    <div
      class="vx-row m-1"
      v-for="entity in config.entities()"
      :key="entity.id"
    >
      <div class="vx-col sm:w-1/12 text-center" v-if="!config.readonly()">
        <feather-icon
          icon="TrashIcon"
          svgClasses="w-6 h-6 text-danger m-2 cursor-pointer"
          @click="() => config.onRemove(entity.id, model)"
        />
      </div>
      <div class="vx-col sm:w-11/12 vx-row">
        <div
          class="vx-col"
          :class="`md:w-${c.size} sm:w-${c.mobileSize} ${c.extraClasses || ''}`"
          v-for="(c, pos) in config.columns"
          :key="`value_${pos}`"
        >
          <template v-if="c.type === 'many'">
            <MultiEditField
              :config="config"
              :column="{ ...x, size: '1/1' }"
              :entity="entity"
              :model="model"
              v-for="x in c.fields"
              :key="x.key"
              class="mb-2"
            />
          </template>
          <MultiEditField
            v-else
            :config="config"
            :column="c"
            :entity="entity"
            :model="model"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MultiEditField from "./MultiEditField.vue";

export default {
  name: "MultiEdit",
  props: {
    config: Object,
    model: Object,
  },
  components: {
    MultiEditField,
  },
};
</script>
