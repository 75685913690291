<template>
  <div class="mt-5" v-if="originalData">
    <div class="vx-col w-full">
      <vx-card :class="processedTabs.length === 1 ? 'hide-tabs' : ''">
        <slot name="formHeader" v-bind="headerData" />
        <vs-tabs alignment="fixed" v-model="activeTab">
          <vs-tab
            :label="$t(t.name)"
            v-for="(t, i) in processedTabs"
            v-bind:key="`tab_${i}`"
          >
            <template v-for="(r, j) in t.rows">
              <vs-divider
                border-style="dashed"
                color="primary"
                :key="`separator_${i}_${j}`"
                v-if="
                  j > 0 &&
                  r.attributes.some(
                    (f) =>
                      !(
                        (!!f.visibility && !f.visibility(model)) ||
                        f.hideOnInvisible
                      )
                  )
                "
              />
              <div class="vx-row" :key="`row_${i}_${j}`">
                <template v-for="(f, p) in r.attributes">
                  <div
                    class="vx-col w-full mb-2"
                    :class="{
                      [`sm:w-${f.size}`]: true,
                      hidden:
                        !(!f.visibility || f.visibility(model)) &&
                        f.hideOnInvisible,
                    }"
                    v-bind:key="`field_${p}`"
                  >
                    <template v-if="!f.visibility || f.visibility(model)">
                      <label
                        v-if="!!f.attribute && !f.hideLabel"
                        class="vs-input--label"
                        :class="{
                          'text-danger': modifications.hasOwnProperty(
                            f.attribute
                          ),
                        }"
                      >
                        {{ $t(`${collectionName}.fields.${f.attribute}`) }}
                      </label>
                      <br />
                      <div
                        class="vs-con-input"
                        v-if="f.readonly && !['files'].includes(f.type)"
                      >
                        <div
                          class="vs-inputx vs-input--input normal hasValue whitespace-pre-wrap"
                          style="background: #f8f8f8"
                          v-html="readonlyValue(f)"
                        />
                      </div>
                      <vs-input
                        v-else-if="f.type === 'input'"
                        class="w-full"
                        v-model="model[f.attribute]"
                        :name="f.attribute"
                        v-validate="f.validation"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />
                      <vs-input
                        v-else-if="f.type === 'number'"
                        type="number"
                        class="w-full"
                        v-model.number="model[f.attribute]"
                        :name="f.attribute"
                        :lang="$i18n.locale"
                        v-validate="f.validation"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />

                      <vs-textarea
                        v-else-if="f.type === 'text'"
                        class="w-full"
                        v-model="model[f.attribute]"
                        :name="f.attribute"
                        v-validate="f.validation"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                        :rows="f.rows || 10"
                      />
                      <sketch-picker
                        v-else-if="f.type === 'color'"
                        :value="model[f.attribute] || 'rgb(115, 103, 240)'"
                        @input="(e) => updateColor(e, f)"
                        :name="f.attribute"
                        v-validate="f.validation"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />
                      <template v-else-if="f.type === 'datetime'">
                        <datetime
                          v-model="model[f.attribute]"
                          type="datetime"
                          input-class="flatpickr-input vs-input--input"
                          format="dd-LL-yyyy HH:mm"
                          :phrases="{
                            ok: $t('buttons.ok'),
                            cancel: $t('buttons.cancel'),
                          }"
                          :minute-step="5"
                          :name="f.attribute"
                          v-validate="f.validation || ''"
                          :data-vv-as="
                            $t(`${collectionName}.fields.${f.attribute}`)
                          "
                          :disabled="
                            f.disabledFunction
                              ? f.disabledFunction(model)
                              : f.disabled
                          "
                        />
                        <a
                          class="flatpickr-clear"
                          @click="model[f.attribute] = null"
                          v-if="
                            f.validation !== 'required' && !!model[f.attribute]
                          "
                        >
                          <feather-icon
                            icon="XIcon"
                            svgClasses="h-4 w-4"
                            class="flatpickr-clear-button ml-1"
                          />
                        </a>
                      </template>
                      <template v-else-if="f.type === 'date'">
                        <datetime
                          v-model="model[f.attribute]"
                          type="date"
                          input-class="flatpickr-input vs-input--input"
                          format="dd-LL-yyyy"
                          :phrases="{
                            ok: $t('buttons.ok'),
                            cancel: $t('buttons.cancel'),
                          }"
                          :minute-step="5"
                          :name="f.attribute"
                          v-validate="f.validation || ''"
                          :data-vv-as="
                            $t(`${collectionName}.fields.${f.attribute}`)
                          "
                          :disabled="
                            f.disabledFunction
                              ? f.disabledFunction(model)
                              : f.disabled
                          "
                          value-zone="UTC"
                          zone="UTC"
                        />
                        <a
                          class="flatpickr-clear"
                          @click="model[f.attribute] = null"
                          v-if="
                            f.validation !== 'required' && !!model[f.attribute]
                          "
                        >
                          <feather-icon
                            icon="XIcon"
                            svgClasses="h-4 w-4"
                            class="flatpickr-clear-button ml-1"
                          />
                        </a>
                      </template>
                      <v-select
                        v-else-if="f.type === 'enum'"
                        label="alias"
                        :reduce="
                          (option) =>
                            f.valueFormatter
                              ? f.valueFormatter(option)
                              : {
                                  id: option.id,
                                  key: option.key,
                                  alias: option.alias,
                                }
                        "
                        :options="
                          (f.extra.optionsFunction
                            ? f.extra.optionsFunction(model)
                            : f.extra.options
                          )
                            .map((x) => ({
                              id: x.id,
                              key: x.key,
                              alias: $t(`enums.${f.extra.enumType}.${x.key}`),
                            }))
                            .sort((a, b) => {
                              return f.extra.customSort
                                ? f.extra.customSort(a, b)
                                : a.alias.localeCompare(b.alias);
                            })
                        "
                        :getOptionLabel="(o) => getOptionLabel(o, f)"
                        :clearable="
                          !f.validation || f.validation.indexOf('required') < 0
                        "
                        @search="
                          (val) => {
                            f.extra.search
                              ? f.extra.search(val, model)
                              : () => {};
                          }
                        "
                        :filterable="!f.extra.search"
                        v-model="model[f.attribute]"
                        class="mb-4 md:mb-0"
                        v-validate="f.validation"
                        :name="f.attribute"
                        :filterBy="selectFilter"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />
                      <v-select
                        v-else-if="f.type === 'multiple-enum'"
                        label="alias"
                        :reduce="
                          (option) =>
                            f.valueFormatter
                              ? f.valueFormatter(option)
                              : {
                                  id: option.id,
                                  key: option.key,
                                  alias: option.alias,
                                }
                        "
                        :options="
                          (f.extra.optionsFunction
                            ? f.extra.optionsFunction(model)
                            : f.extra.options
                          )
                            .map((x) => ({
                              id: x.id,
                              key: x.key,
                              alias: $t(`enums.${f.extra.enumType}.${x.key}`),
                            }))
                            .sort((a, b) => {
                              return f.extra.customSort
                                ? f.extra.customSort(a, b)
                                : a.alias.localeCompare(b.alias);
                            })
                        "
                        :getOptionLabel="(o) => getOptionLabel(o, f)"
                        :clearable="
                          !f.validation || f.validation.indexOf('required') < 0
                        "
                        multiple
                        v-model="model[f.attribute]"
                        class="mb-4 md:mb-0"
                        v-validate="f.validation"
                        :name="f.attribute"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />
                      <v-select
                        v-else-if="f.type === 'select'"
                        label="alias"
                        :reduce="
                          (option) =>
                            f.valueFormatter
                              ? f.valueFormatter(option)
                              : { id: option.id, alias: option.alias }
                        "
                        :options="
                          f.extra.optionsFunction
                            ? f.extra.optionsFunction(model)
                            : f.extra.options
                        "
                        :getOptionLabel="(o) => getOptionLabel(o, f)"
                        :clearable="
                          !f.validation || f.validation.indexOf('required') < 0
                        "
                        @search="
                          (val) => {
                            f.extra.search
                              ? f.extra.search(val, model)
                              : () => {};
                          }
                        "
                        :filterable="!f.extra.search"
                        v-model="model[f.attribute]"
                        class="mb-4 md:mb-0"
                        v-validate="f.validation"
                        :name="f.attribute"
                        :filterBy="selectFilter"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />
                      <v-select
                        v-else-if="f.type === 'multiple-select'"
                        label="alias"
                        :reduce="
                          (option) =>
                            f.valueFormatter
                              ? f.valueFormatter(option)
                              : { id: option.id, alias: option.alias }
                        "
                        :options="
                          f.extra.optionsFunction
                            ? f.extra.optionsFunction(model)
                            : f.extra.options
                        "
                        :getOptionLabel="(o) => getOptionLabel(o, f)"
                        :clearable="
                          !f.validation || f.validation.indexOf('required') < 0
                        "
                        multiple
                        v-model="model[f.attribute]"
                        class="mb-4 md:mb-0"
                        v-validate="f.validation"
                        :name="f.attribute"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />
                      <v-select
                        v-else-if="f.type === 'boolean'"
                        :reduce="(option) => option.value"
                        :options="[
                          { label: $t('boolean.yes'), value: true },
                          { label: $t('boolean.no'), value: false },
                        ]"
                        :clearable="
                          !f.validation || f.validation.indexOf('required') < 0
                        "
                        v-model="model[f.attribute]"
                        class="mb-4 md:mb-0"
                        v-validate="f.validation"
                        :name="f.attribute"
                        :data-vv-as="
                          $t(`${collectionName}.fields.${f.attribute}`)
                        "
                        :disabled="
                          f.disabledFunction
                            ? f.disabledFunction(model)
                            : f.disabled
                        "
                      />

                      <ListPage
                        v-else-if="f.type === 'list'"
                        :gridData="f.extra.data()"
                        :gridConfig="f.extra.gridConfig"
                        :filters="f.extra.filters"
                      >
                        <template
                          v-for="(_, slot) of $scopedSlots"
                          v-slot:[slot]="scope"
                        >
                          <slot :name="slot" v-bind="scope" />
                        </template>
                      </ListPage>

                      <MultiEdit
                        v-else-if="f.type === 'multiEdit'"
                        :config="f.config"
                        :model="model"
                      />
                      <slot
                        v-else-if="f.type === 'custom'"
                        :name="f.template"
                        v-bind="{ model, ...f.data }"
                      />
                      <vs-alert
                        v-else-if="f.type === 'alert'"
                        :active="true"
                        :color="f.color"
                        :icon="f.icon"
                      >
                        <span>{{ f.text }}</span>
                      </vs-alert>

                      <div class="vx-row" v-else-if="f.type === 'files'">
                        <div class="vx-col w-full mb-base">
                          <vue-dropzone
                            :ref="f.attribute"
                            id="drop2"
                            :options="f.extra.config"
                            @vdropzone-file-added="
                              (file) => onFileAdded(file, f.attribute, f)
                            "
                            v-if="
                              !f.readonly &&
                              !(f.disabledFunction
                                ? f.disabledFunction(model)
                                : f.disabled)
                            "
                          />
                        </div>
                        <slot
                          v-if="f.extra.listingTemplate"
                          :name="f.extra.listingTemplate"
                          v-bind="{ files: f.extra.files() }"
                        />
                        <div
                          v-else
                          v-for="(file, id) in f.extra.files()"
                          :key="id"
                          class="vx-col w-full lg:w-1/5 sm:w-1/2 mb-base"
                        >
                          <vx-card class="overlay-card overflow-hidden">
                            <template slot="no-body">
                              <img
                                :src="filePreviewCover(file.url, file.type)"
                                class="responsive blur-1"
                              />
                              <div class="card-overlay text-white">
                                <div class="h-full">
                                  <div class="mt-0 w-full flex justify-between">
                                    <p
                                      class="text-white mb-2 tracking-wide font-medium"
                                      style="max-width: 85%; overflow: hidden"
                                    >
                                      {{ file.name }}
                                    </p>
                                    <a
                                      class="leading-none"
                                      style="cursor: pointer"
                                      target="_blank"
                                      :href="file.url"
                                    >
                                      <feather-icon
                                        icon="EyeIcon"
                                        svgClasses="w-6 h-6 text-white"
                                      />
                                    </a>
                                  </div>

                                  <div
                                    class="text-center w-full absolute bottom-0"
                                  >
                                    <div class="flex justify-between text-sm">
                                      <a
                                        class="leading-none"
                                        style="cursor: pointer"
                                        @click="
                                          confirmRemoveFile(
                                            file.id,
                                            file.name,
                                            f.attribute
                                          )
                                        "
                                        v-if="!f.isReadonly"
                                      >
                                        <feather-icon
                                          icon="TrashIcon"
                                          svgClasses="w-6 h-6 text-white"
                                        />
                                      </a>
                                      <p>
                                        {{
                                          file.name.substring(
                                            file.name.lastIndexOf(".") + 1
                                          )
                                        }}
                                        -
                                        {{
                                          (
                                            file.size / Math.pow(1024, 2)
                                          ).toFixed(2)
                                        }}MB
                                      </p>
                                      <a
                                        class="leading-none"
                                        @click="
                                          downloadWithVueResource(
                                            file.url,
                                            file.name
                                          )
                                        "
                                        style="cursor: pointer"
                                      >
                                        <feather-icon
                                          icon="ArrowDownIcon"
                                          svgClasses="w-6 h-6 text-white"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </vx-card>
                        </div>
                      </div>

                      <span
                        class="text-danger text-sm"
                        v-show="errors.has(f.attribute)"
                      >
                        {{ errors.first(f.attribute) }}
                      </span>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>

    <div class="vx-col w-full mt-base" v-if="!formIsReadOnly">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button
            color="success"
            class="mr-3 mb-2"
            @click="onSave"
            :disabled="!formForSave"
            >{{ $t("buttons.save") }}</vs-button
          >
          <vs-button
            color="danger"
            type="border"
            class="mr-3 mb-2"
            @click="onCancel"
          >
            {{ $t("buttons.cancel") }}
          </vs-button>
          <slot name="customButtons" v-bind="customButtonsData" />
          <vs-button
            v-if="showDeleteButton"
            color="danger"
            class="mr-3 mb-2 float-right"
            @click="onRemove"
            icon-pack="feather"
            icon="icon-trash"
          >
            {{ $t("buttons.remove") }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { diff, applyChange } from "deep-diff";
import vSelect from "vue-select";
import vueDropzone from "vue2-dropzone";
import { Sketch } from "vue-color";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";
import { Settings } from "luxon";

import ListPage from "@/components/penal-retail-helpers/ListPage";
import MultiEdit from "@/components/penal-retail-helpers/MultiEdit";
import { db } from "@/firebase/firebaseConfig";
import firebase from "firebase/app";

export default {
  components: {
    ListPage,
    MultiEdit,
    vSelect,
    vueDropzone,
    "sketch-picker": Sketch,
    datetime: Datetime,
  },
  props: {
    headerData: Object,
    customButtonsData: Object,
    defaultActiveTab: Number,
    collectionName: String,
    tabs: Array,
    id: String,
    showDeleteButton: Boolean,
    prevalidate: Function,
    defaultData: Object,
    pathPrefix: String,
    formIsReadOnly: Boolean,
    customModelProcessor: Function,
  },
  created() {
    Settings.defaultLocale = this.$i18n.locale;
    if (this.id) {
      this.$bind(
        "originalData",
        db.collection(this.collectionName).doc(this.id),
        { wait: true }
      );
    } else if (this.defaultData) {
      this.originalData = this.defaultData;
    }
  },
  watch: {
    activeTab() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    },
    originalData() {
      if (this.customModelProcessor) {
        this.originalData = this.customModelProcessor(this.originalData);
      }

      //Aquí formateamos todas las fechas de timestamp a date (para usaralas en el datepicker)
      this.dateFields.forEach((a) => {
        let val = this.originalData[a];
        if (!!val && typeof val === "object") {
          this.originalData[a] = val ? val.toDate().toISOString() : "";
        } else {
          this.originalData[a] = "";
        }
      });

      //Actualizamos nuestro modelo para que tenga una copia de los datos de la base de datos
      this.model = { ...this.originalData };

      //Aplicamos sobre nuestro modelo todas aquellas modificaciones que podamos tener pendiente de guardar.
      Object.keys(this.modifications).forEach((m) => {
        this.model[m] = this.modifications[m];
      });

      if (!Object.keys(this.baseModel).length) {
        this.fieldsWithOnChange.forEach((field) => {
          field.onChange(this.model);
        });

        this.fieldsWithDependency.forEach((field) => {
          field.onDependencyChanged(this.model);
        });
      }

      this.baseModel = { ...this.model };
    },
    model: {
      deep: true,
      handler() {
        (diff(this.baseModel, this.model) || []).forEach((d) => {
          let att = d.path[0];

          const field = this.fieldsWithOnChange.find(
            (x) => x.attribute === att
          );
          if (field) {
            field.onChange(this.model);
          }

          this.fieldsWithDependency
            .filter((x) => x.dependencies.includes(att))
            .forEach((field) => {
              field.onDependencyChanged(this.model);
            });
        });

        this.modifications = {};
        (diff(this.originalData, this.model) || []).forEach((d) => {
          let att = d.path[0];
          if (d.path.length > 1 && !Array.isArray(this.model[att])) {
            applyChange(this.modifications, this.model, d);
          } else {
            this.modifications[att] = this.model[att];
          }
        });

        this.baseModel = { ...this.model };
      },
    },
  },
  data() {
    return {
      activeTab: this.defaultActiveTab || 0,
      modifications: {},
      originalData: null,
      model: {},
      baseModel: {},
    };
  },
  computed: {
    processedTabs() {
      return this.tabs.map((t) => {
        if (!t.rows) {
          return {
            name: t.name,
            rows: [
              {
                attributes: t.attributes.map((a) => ({
                  ...a,
                  readonly:
                    a.readonly ||
                    (!["list", "files", "multiEdit"].includes(a.type) &&
                      this.formIsReadOnly),
                })),
              },
            ],
          };
        } else {
          return t;
        }
      });
    },
    dateFields() {
      return [
        ...new Set(
          this.processedTabs
            .map((t) =>
              t.rows.map((r) =>
                r.attributes.filter(
                  (a) => a.type === "datetime" || a.type === "date"
                )
              )
            )
            .flat(2)
            .map((x) => x.attribute)
        ),
      ];
    },
    fieldsWithOnChange() {
      return [
        ...this.processedTabs
          .map((t) =>
            t.rows.map((r) => r.attributes.filter((a) => !!a.onChange))
          )
          .flat(2),
      ];
    },
    fieldsWithDependency() {
      return [
        ...this.processedTabs
          .map((t) =>
            t.rows.map((r) =>
              r.attributes.filter(
                (a) =>
                  !!a.dependencies &&
                  !!a.dependencies.length &&
                  !!a.onDependencyChanged
              )
            )
          )
          .flat(2),
      ];
    },
    formForSave() {
      return diff(this.originalData, this.model);
    },
  },
  methods: {
    updateColor(e, f) {
      this.model[f.attribute] = e.hex;

      this.model = Object.assign({}, this.model);
    },
    getOptionLabel(option, f) {
      if (option) {
        let options = f.extra.optionsFunction
          ? f.extra.optionsFunction(this.model)
          : f.extra.options;

        if (["enum", "multiple-enum"].includes(f.type)) {
          options = options.map((x) => ({
            id: x.id,
            key: x.key,
            alias: this.$t(`enums.${f.extra.enumType}.${x.key}`),
          }));
        }
        let optionId = option.id ? option.id : option;
        let found =
          options.find((v) => v.id === optionId) ||
          this.model[f.attribute] ||
          {};
        return found.alias || null;
      } else {
        return null;
      }
    },
    selectFilter(option, label, search) {
      const removeDiacritics = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      return (
        removeDiacritics(label).toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    filePreviewCover(url, type) {
      let result;
      switch (type) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
        case "image/bmp":
        case "image/webp":
        case "image/tiff":
          result = url;
          break;
        case "application/pdf":
          result = require("@/assets/images/pdf-cover.webp");
          break;
        case "application/zip":
          result = require("@/assets/images/zip-cover.png");
          break;
        case "video/mpeg":
        case "video/mp4":
        case "video/quicktime":
        case "video/x-msvideo":
        case "video/x-ms-wmv":
        case "video/x-flv":
          result = require("@/assets/images/video-cover.jpg");
          break;
        default:
          result = require("@/assets/images/file-cover.jpg");
      }
      return result;
    },
    onFileSending(file) {
      if (!file.accepted) {
        // abort doesn't work. Setting the timeout is the only thing I've found that cancels the s3 request.
        file.xhr.timeout = 1;
      }
    },
    onFileAdded(file, name, field) {
      if (file.accepted === undefined) {
        setTimeout(() => {
          this.onFileAdded(file, name, field);
        }, 200);
      } else {
        let ref = this.$refs[name][0];
        if (file.accepted) {
          let progressBar = file.previewElement.children[2];
          let uploadRef = firebase
            .storage()
            .ref()
            .child(this.collectionName)
            .child(this.$route.params.id)
            .child(name)
            .child(Date.now().toString() + Math.random().toString())
            .child(file.name);

          let uploadTask = uploadRef.put(file);

          progressBar.opacity = 1;

          uploadTask.on(
            "state_changed",
            function (snapshot) {
              let progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              progressBar.children[0].style.width = progress + "%";
            },
            function (error) {
              console.log(error);
            },
            async () => {
              let url = await uploadTask.snapshot.ref.getDownloadURL();
              let meta = await uploadRef.getMetadata();

              let fileo = {
                [field.extra.relationKey || "entityId"]: this.$route.params.id,
                deleted: false,
                ref: uploadRef.toString(),
                bucket: uploadRef.bucket,
                fullPath: uploadRef.fullPath,
                name: uploadRef.name,
                url: url,
                size: meta.size,
                type: meta.contentType,
                createdAt: firebase.firestore.Timestamp.now(),
                createdBy: {
                  id: this.$store.state.AppActiveUser.uid,
                  email: this.$store.state.AppActiveUser.email,
                },
              };

              db.collection(name)
                .add(fileo)
                .then((docRef) => {
                  console.log("File written with ID: ", docRef.id);
                  this.$vs.notify({
                    title: this.$t("messages.success.fileCreated", {
                      name: file.name,
                    }),
                    color: "success",
                  });
                })
                .catch((error) => {
                  console.error("Error adding file: ", error);
                });

              progressBar.style.opacity = 0;
              ref.removeFile(file);
            }
          );
        } else {
          //No es un archivo aceptado... lo quitamos en 500 milisegundos
          this.$vs.notify({
            title: this.$t("messages.errors.fileInvalid", { name: file.name }),
            color: "danger",
          });
          ref.removeFile(file);
        }
      }
    },
    confirmRemoveFile(id, name, fileCollectionName) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("modals.fileRemoveConfirmation.title", { name }),
        text: this.$t("modals.fileRemoveConfirmation.description"),
        acceptText: this.$t("buttons.confirm"),
        cancelText: this.$t("buttons.cancel"),

        accept: (id) => {
          let fileRef = db.collection(fileCollectionName).doc(id);

          fileRef
            .update({
              deleted: true,
            })
            .then(() => {
              console.log("File deleted");
            })
            .catch((error) => {
              console.log("Error deleting case file from database", error);
            });
        },
        parameters: id,
      });
    },
    downloadWithVueResource(url, name) {
      this.$http({ method: "get", url: url, responseType: "arraybuffer" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => console.log("error occured"));
    },

    readonlyValue(f) {
      if (
        this.model[f.attribute] !== null &&
        this.model[f.attribute] !== undefined &&
        this.model[f.attribute] !== ""
      ) {
        if (f.type === "select") {
          return this.model[f.attribute].alias;
        } else if (f.type === "enum") {
          return this.getOptionLabel(this.model[f.attribute], f);
        } else if (f.type === "boolean") {
          return this.$t(`boolean.${this.model[f.attribute] ? "yes" : "no"}`);
        } else if (f.type === "datetime") {
          return this.$moment(this.model[f.attribute]).format(
            "DD-MM-YYYY HH:mm"
          );
        } else if (f.type === "multiple-select") {
          return (this.model[f.attribute] || []).map((x) => x.alias).join(", ");
        } else if (f.type === "multiple-enum") {
          return (this.model[f.attribute] || [])
            .map((x) => this.getOptionLabel(x, f))
            .join(", ");
        } else if (!["input", "text", "number"].includes(f.type)) {
          console.log("HACER ESTE: ", f.type);
        }
      } else {
        return "&nbsp;";
      }

      return this.model[f.attribute];
    },
    onSave() {
      this.$validator.validateAll().then(async (result) => {
        let prevalidateErrors = await this.prevalidate(
          this.model,
          db.collection(this.collectionName),
          this.errors
        );
        if (result && !prevalidateErrors) {
          try {
            const dataToSave = this.id
              ? { ...this.modifications }
              : { ...this.model };

            //Preprocesamos los campos fecha...
            this.dateFields.forEach((a) => {
              if (dataToSave.hasOwnProperty(a)) {
                dataToSave[a] = dataToSave[a]
                  ? firebase.firestore.Timestamp.fromDate(
                      new Date(dataToSave[a])
                    )
                  : null;
              }
            });

            if (this.id) {
              await db
                .collection(this.collectionName)
                .doc(this.id)
                .update(
                  Object.assign(dataToSave, {
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    updatedBy: {
                      email: this.$store.state.AppActiveUser.email,
                      uid: this.$store.state.AppActiveUser.uid,
                    },
                  })
                );
              this.$vs.notify({
                title: this.$t("messages.success.docUpdated"),
                color: "success",
              });
            } else {
              let docRef = await db.collection(this.collectionName).add(
                Object.assign(dataToSave, {
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  createdBy: {
                    email: this.$store.state.AppActiveUser.email,
                    uid: this.$store.state.AppActiveUser.uid,
                  },
                  deleted: false,
                })
              );
              this.$router.push(
                `/${this.pathPrefix || "settings"}/${this.collectionName}/${
                  docRef.id
                }`
              );
            }
          } catch (error) {
            console.error(
              this.collectionName,
              "Error adding or editing document: ",
              this.id,
              error
            );
          }
        } else {
          this.$vs.notify({
            title: this.$t("messages.errors.reviewDoc"),
            color: "danger",
          });
        }
      });
    },

    onCancel() {
      this.$router
        .push(`/${this.pathPrefix || "settings"}/${this.collectionName}`)
        .catch(() => {});
    },

    onRemove() {
      if (this.showDeleteButton) {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: this.$t("modals.entityRemoveConfirmation.title"),
          text: this.$t("modals.entityRemoveConfirmation.description"),
          acceptText: this.$t("buttons.confirm"),
          cancelText: this.$t("buttons.cancel"),
          accept: this.removeConfirmed,
        });
      }
    },
    async removeConfirmed() {
      try {
        await db
          .collection(this.collectionName)
          .doc(this.$route.params.id)
          .update({ deleted: true });

        this.$vs.notify({
          title: this.$t("messages.success.docRemoved"),
          color: "success",
        });
        this.$router.push(
          `/${this.pathPrefix || "settings"}/${this.collectionName}`
        );
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: this.$t("messages.errors.docRemoval"),
          color: "danger",
        });
      }
    },
  },
};
</script>
<style>
.con-vs-tabs {
  overflow: visible;
}
.con-slot-tabs {
  overflow: visible !important;
}
</style>
<style lang="scss">
.hide-tabs {
  .con-ul-tabs {
    display: none;
  }

  .con-slot-tabs .con-tab {
    padding: 0;
  }
}
</style>
